exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-scste-js": () => import("./../../../src/pages/about/About_scste.js" /* webpackChunkName: "component---src-pages-about-about-scste-js" */),
  "component---src-pages-about-whos-who-js": () => import("./../../../src/pages/about/Who's_who.js" /* webpackChunkName: "component---src-pages-about-whos-who-js" */),
  "component---src-pages-contact-details-js": () => import("./../../../src/pages/Contact_details.js" /* webpackChunkName: "component---src-pages-contact-details-js" */),
  "component---src-pages-dashboards-gis-dashboard-js": () => import("./../../../src/pages/dashboards/GIS_dashboard.js" /* webpackChunkName: "component---src-pages-dashboards-gis-dashboard-js" */),
  "component---src-pages-dashboards-scste-dashboard-js": () => import("./../../../src/pages/dashboards/SCSTE_dashboard.js" /* webpackChunkName: "component---src-pages-dashboards-scste-dashboard-js" */),
  "component---src-pages-explore-chess-form-js": () => import("./../../../src/pages/explore/chess-form.js" /* webpackChunkName: "component---src-pages-explore-chess-form-js" */),
  "component---src-pages-explore-display-chess-form-js": () => import("./../../../src/pages/explore/display_chess_form.js" /* webpackChunkName: "component---src-pages-explore-display-chess-form-js" */),
  "component---src-pages-explore-display-star-30-registration-form-js": () => import("./../../../src/pages/explore/display_star_30_registration_form.js" /* webpackChunkName: "component---src-pages-explore-display-star-30-registration-form-js" */),
  "component---src-pages-explore-photo-gallery-js": () => import("./../../../src/pages/explore/Photo_gallery.js" /* webpackChunkName: "component---src-pages-explore-photo-gallery-js" */),
  "component---src-pages-explore-star-30-student-registration-form-js": () => import("./../../../src/pages/explore/star-30-student-registration-form.js" /* webpackChunkName: "component---src-pages-explore-star-30-student-registration-form-js" */),
  "component---src-pages-explore-youtube-js": () => import("./../../../src/pages/explore/Youtube.js" /* webpackChunkName: "component---src-pages-explore-youtube-js" */),
  "component---src-pages-footer-accessibility-statement-js": () => import("./../../../src/pages/footer/accessibility_statement.js" /* webpackChunkName: "component---src-pages-footer-accessibility-statement-js" */),
  "component---src-pages-footer-disclaimer-js": () => import("./../../../src/pages/footer/disclaimer.js" /* webpackChunkName: "component---src-pages-footer-disclaimer-js" */),
  "component---src-pages-footer-term-condition-js": () => import("./../../../src/pages/footer/term&condition.js" /* webpackChunkName: "component---src-pages-footer-term-condition-js" */),
  "component---src-pages-footer-website-policy-js": () => import("./../../../src/pages/footer/website_policy.js" /* webpackChunkName: "component---src-pages-footer-website-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-notification-detail-js": () => import("./../../../src/pages/latest_notification_detail.js" /* webpackChunkName: "component---src-pages-latest-notification-detail-js" */),
  "component---src-pages-latest-notifications-js": () => import("./../../../src/pages/latest_notifications.js" /* webpackChunkName: "component---src-pages-latest-notifications-js" */),
  "component---src-pages-new-feed-js": () => import("./../../../src/pages/new-feed.js" /* webpackChunkName: "component---src-pages-new-feed-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/News.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-notice-board-notifications-js": () => import("./../../../src/pages/notice_board/Notifications.js" /* webpackChunkName: "component---src-pages-notice-board-notifications-js" */),
  "component---src-pages-notice-board-recruitment-js": () => import("./../../../src/pages/notice_board/Recruitment.js" /* webpackChunkName: "component---src-pages-notice-board-recruitment-js" */),
  "component---src-pages-notice-board-tender-js": () => import("./../../../src/pages/notice_board/Tender.js" /* webpackChunkName: "component---src-pages-notice-board-tender-js" */),
  "component---src-pages-notification-js": () => import("./../../../src/pages/notification.js" /* webpackChunkName: "component---src-pages-notification-js" */),
  "component---src-pages-popup-js": () => import("./../../../src/pages/popup.js" /* webpackChunkName: "component---src-pages-popup-js" */),
  "component---src-pages-programmes-edp-js": () => import("./../../../src/pages/programmes/Edp.js" /* webpackChunkName: "component---src-pages-programmes-edp-js" */),
  "component---src-pages-programmes-ld-js": () => import("./../../../src/pages/programmes/Ld.js" /* webpackChunkName: "component---src-pages-programmes-ld-js" */),
  "component---src-pages-programmes-other-js": () => import("./../../../src/pages/programmes/Other.js" /* webpackChunkName: "component---src-pages-programmes-other-js" */),
  "component---src-pages-programmes-psp-js": () => import("./../../../src/pages/programmes/Psp.js" /* webpackChunkName: "component---src-pages-programmes-psp-js" */),
  "component---src-pages-programmes-rsap-js": () => import("./../../../src/pages/programmes/Rsap.js" /* webpackChunkName: "component---src-pages-programmes-rsap-js" */),
  "component---src-pages-programmes-spepp-js": () => import("./../../../src/pages/programmes/Spepp.js" /* webpackChunkName: "component---src-pages-programmes-spepp-js" */),
  "component---src-pages-programmes-spopp-js": () => import("./../../../src/pages/programmes/Spopp.js" /* webpackChunkName: "component---src-pages-programmes-spopp-js" */),
  "component---src-pages-programmes-tp-js": () => import("./../../../src/pages/programmes/Tp.js" /* webpackChunkName: "component---src-pages-programmes-tp-js" */),
  "component---src-pages-publications-nl-js": () => import("./../../../src/pages/publications/Nl.js" /* webpackChunkName: "component---src-pages-publications-nl-js" */),
  "component---src-pages-publications-reports-js": () => import("./../../../src/pages/publications/Reports.js" /* webpackChunkName: "component---src-pages-publications-reports-js" */),
  "component---src-pages-publications-resources-js": () => import("./../../../src/pages/publications/Resources.js" /* webpackChunkName: "component---src-pages-publications-resources-js" */),
  "component---src-pages-publications-tsd-js": () => import("./../../../src/pages/publications/Tsd.js" /* webpackChunkName: "component---src-pages-publications-tsd-js" */),
  "component---src-pages-react-home-react-js": () => import("./../../../src/pages/react/home-react.js" /* webpackChunkName: "component---src-pages-react-home-react-js" */),
  "component---src-pages-react-react-notification-detail-js": () => import("./../../../src/pages/react/react_notification_detail.js" /* webpackChunkName: "component---src-pages-react-react-notification-detail-js" */),
  "component---src-pages-react-react-notification-js": () => import("./../../../src/pages/react/react_notification.js" /* webpackChunkName: "component---src-pages-react-react-notification-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/Report.js" /* webpackChunkName: "component---src-pages-report-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-social-media-js": () => import("./../../../src/pages/SocialMedia.js" /* webpackChunkName: "component---src-pages-social-media-js" */),
  "component---src-pages-viewmorevideos-js": () => import("./../../../src/pages/viewmorevideos.js" /* webpackChunkName: "component---src-pages-viewmorevideos-js" */),
  "component---src-pages-youtube-js": () => import("./../../../src/pages/youtube.js" /* webpackChunkName: "component---src-pages-youtube-js" */),
  "component---src-templates-display-new-js": () => import("./../../../src/templates/display_new.js" /* webpackChunkName: "component---src-templates-display-new-js" */),
  "component---src-templates-display-report-js": () => import("./../../../src/templates/display_report.js" /* webpackChunkName: "component---src-templates-display-report-js" */)
}

